import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import SubTitle from '../components/page-subtitle'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import { theme } from '../util/style'
import PageButtons from '../components/page-buttons'
//import { setLightboxForm } from "../actions";
import Image from '../components/page-image-new'

//import PageOverview from "../components/page-overview";
import Image1 from '../images/designs/happy-haus-new-farm-3.jpg'
import Image2 from '../images/designs/happy-haus-helensvale-7.jpg'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]
const InclusionImage1 = 'designs/happy-haus-new-farm-3.jpg'
const InclusionImage3 = 'designs/happyhaus-gallery-photo-dewar-AM-3.jpg'
const InclusionsLink =
  'https://f.hubspotusercontent00.net/hubfs/6193006/happyhaus-specifications.pdf'
const BuliderChecklist =
  'https://f.hubspotusercontent00.net/hubfs/6193006/happyhaus-builder-checklist.pdf'

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Selections"
          description="Our architects and designers specify equipment, finishes and detail to complement our homes. We choose trusted brands like
                  Dulux, Colorbond and Ilve. We specify quality fittings and kitchen equipment that complement each home."
          images={ogImages}
        />

        <Row noPad>
          <Flex>
            <Box width={1} px={2}>
              <Image src={InclusionImage3} alt="" />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
              <Title>Our specifications</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <Title align="left">
                <p>A Happy Haus is beautiful yet practical.</p>
              </Title>
              <Copy>
                <p>
                It is a home that engages with its surrounds and maximises the ability to enjoy the benefits of living in the subtropics. While on the inside, it iscomplete with stunning interior design and carefullyconsidered luxury specifications.
                </p>
              </Copy>
              <PageButtons
                data={[
                  {
                    label: 'Download specifications',
                    link: InclusionsLink,
                    external: true,
                  },
                ]}
              />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 100]} pb={[3, 0]}>
              <Image src={InclusionImage1} />
            </Box>
            <Box width={[1, 6 / 12]} px={2} alignSelf="flex-start">
              <SubTitle align="left">Luxury inside and out</SubTitle>
              <Copy>
                <p>
                  Our architects and designers specify equipment, finishes and
                  detail to complement our homes. We choose trusted brands like
                  Dulux, Colorbond, Ilve, Polytec and ABI. We specify quality
                  fittings and kitchen equipment that complement each home.
                </p>
              </Copy>
              {/* <PageButtons
                align="left"
                data={[
                  {
                    label: 'Download builder checklist',
                    link: BuliderChecklist,
                    external: true,
                    background: theme.colors.grey1,
                  },
                ]}
              /> */}
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default Page
