import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { fontBold } from '../util/style'

const PageSubtitle = ({ align, children }) => (
  <Title align={align}>
    {children}
  </Title>
)

PageSubtitle.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string
}

export default PageSubtitle

const Title = styled.div`
  ${ fontBold() };
  font-size: 110%;
  text-align: ${props => props.align ? props.align : 'center'};
  margin-bottom: 20px;
`